<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        a) Using the formal charges, determine which of the structures below would be the most
        stable.
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="part in parts" :key="part.imageUrl" class="my-1" :value="part.value">
          <template #label>
            <v-img :src="part.imageUrl" max-width="375px" height="auto" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q4',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      parts: [
        {imageUrl: '/img/assignments/CNOstructure1.png', value: 'a'},
        {imageUrl: '/img/assignments/CNOstructure2.png', value: 'b'},
        {imageUrl: '/img/assignments/CNOstructure3.png', value: 'c'},
      ],
    };
  },
};
</script>
